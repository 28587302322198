import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { mergeArrayObjectsById } from "@/app/util.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import {
  QUEUE_SYMBOLS,
  SERVICES_TO_REGIONS,
} from "@/game-lol/constants/constants.mjs";
import {
  CURR_SEASON_PROMO,
  SUPPORTED_QUEUES,
} from "@/game-lol/constants/season-review.mjs";
import definition from "@/game-lol/definition.mjs";
import LCUMatch from "@/game-lol/models/lol-match-lcu.mjs";
import LCUMatchList from "@/game-lol/models/lol-match-list-lcu.mjs";
import LoLPlayerChampionSeasonStats from "@/game-lol/models/lol-player-champion-season-stats.mjs";
import LCUSummoner from "@/game-lol/models/lol-summoner-lcu.mjs";
import { loadLocalMatchlist } from "@/game-lol/utils/actions.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import {
  getLCUAvailable,
  getLCUMatch,
  getLCUMatchList,
  getLCUSummonerProfile,
} from "@/game-lol/utils/lol-client-api.mjs";
import { matchStatePath } from "@/game-lol/utils/match-utils.mjs";
import { shouldShowPromo } from "@/game-lol/utils/season-review.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import {
  getDerivedId,
  isPBE,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";
import unionBy from "@/util/union-by.mjs";

// path regExp: /lol/profile/(.*)/(.*)
async function fetchData(params, searchParam, state) {
  const [region, name] = params;

  if (isPBE(region)) return;

  if (!region || !name) {
    devError("Missing region or name.");
    return;
  }

  const isUpdate = Boolean(state?.isUpdate);
  let derivedId = getDerivedId(region, name);
  const [gameName, tagLine] = separateDerivedRiotID(name);

  const {
    volatile: { currentSummoner, currentRegion },
  } = readState;

  const isLoggedInAccount = currentSummoner === derivedId;

  const localMatchList =
    (isLoggedInAccount && (await loadLocalMatchlist(derivedId))) || [];

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  const profileOptions = {
    mergeFn: deepMerge,
    shouldFetchIfPathExists: !state?.isUpdate,
  };
  let profile = isUnsupportedRegion
    ? await getData(
        () => getLCUSummonerProfile(name),
        LCUSummoner,
        ["lol", "profiles", derivedId],
        profileOptions,
      ).catch((err) => {
        devError("FAILED TO FETCH LCU PROFILE", err);
      })
    : await API.PLAYER.getProfile({
        region,
        gameName,
        tagLine,
      });

  const profileRanks = API.PLAYER.getProfileRanks({
    region,
    gameName,
    tagLine,
  });

  profile = readState.lol.profiles[derivedId];
  if (!profile?.id) return;

  const riotAccount = profile.riotAccount;
  if (riotAccount) {
    const { gameName, tagLine } = profile.riotAccount;
    derivedId = getDerivedId(region, null, gameName, tagLine);
  }

  // For "recently played with", no queue required
  const playerStylePromise = API.PLAYER.getPlaystyles({
    region,
    profileId: profile.id,
    gameName,
    tagLine,
    options: { shouldFetchIfPathExists: !isUpdate },
  });

  const rankedStatsPromise = API.PLAYER.getChampionAggregates({
    region: region?.toUpperCase(),
    gameName,
    tagLine,
    profileId: profile.id,
    queue: getQueueDetails(QUEUE_SYMBOLS.rankedSoloDuo).gql,
  });
  const aramStatsPromise = API.PLAYER.getChampionAggregates({
    region: region?.toUpperCase(),
    gameName,
    tagLine,
    profileId: profile.id,
    queue: getQueueDetails(QUEUE_SYMBOLS.aram).gql,
  });

  const matchListOptions = {
    shouldFetchIfPathExists: !isUpdate,
    mergeFn: (curValue, newValue) =>
      mergeArrayObjectsById(curValue, newValue, "id", "id"),
  };

  function getMatchListFromAPI() {
    return API.PLAYER.getMatchList({
      region,
      gameName,
      tagLine,
      puuid: profile.puuid,
      options: matchListOptions,
    });
  }

  function getMatchListFromLCU() {
    return getData(
      getLCUMatchList(profile.puuid),
      LCUMatchList,
      ["lol", "matchlists", derivedId],
      matchListOptions,
    );
  }

  const matchlistPromise = (
    useLCU ? getMatchListFromLCU : getMatchListFromAPI
  )().then((matchlist) => {
    if (!state) return null;
    const sanitizedMatchList =
      matchlist && !(matchlist instanceof Error) ? matchlist : [];
    const mergedMatchlist = unionBy(
      localMatchList,
      sanitizedMatchList,
      "id",
    ).sort((a, b) => {
      const aId = Number(a.id.split("_")[1]);
      const bId = b.id.split("_")[1];
      return bId - aId;
    });

    // Only fetch matches that is visible
    const matchesToLoad = mergedMatchlist.filter((match) => {
      const matchIsVisible =
        state.transient?.visibleMatches?.[definition.shortName]?.[match.id];
      return matchIsVisible;
    });

    return Promise.all(
      matchesToLoad.map(({ id }) => {
        const matchPath = matchStatePath(null, null, id);
        const [region, gameId] = id.split("_");
        return useLCU
          ? getData(() => getLCUMatch(gameId), LCUMatch, matchPath, {
              shouldFetchIfPathExists: false,
            })
          : API.PLAYER.getMatch({ region, matchId: gameId });
      }),
    );
  });

  const isOwnProfile = isLinkedSummoner(readState, region, name);
  const seasonReviewPromises =
    shouldShowPromo() && isOwnProfile
      ? SUPPORTED_QUEUES.map((queue) => {
          return getData(
            API.PLAYER.getPlayerChampionSeasonStats({
              region: region.toUpperCase(),
              leagueProfileId: profile.id,
              queue: queue.enum,
              season: CURR_SEASON_PROMO,
            }),
            LoLPlayerChampionSeasonStats,
            [
              "lol",
              "playerChampionSeasonStats",
              CURR_SEASON_PROMO,
              queue.stateKey(derivedId),
            ],
            { shouldFetchIfPathExists: false },
          );
        })
      : [];

  return Promise.all([
    profileRanks,
    matchlistPromise,
    playerStylePromise,
    rankedStatsPromise,
    aramStatsPromise,
    ...seasonReviewPromises,
  ]);
}

export default fetchData;
